<template>
  <transition name="fade" appear appear-active-class="fade-enter-active">
    <div>
      В работе
    </div>
  </transition>
</template>

<script>
import {

} from "@/modules/NSI";

export default {
  name: "ContractWiresTab",
  props: ['contract'],
  data() {
    return {
    }
  },
  methods: {
  },
  async beforeMount() {
  }
}
</script>

<style scoped>

</style>